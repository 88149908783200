function startGame(manifestURL) {

    var params = new URLSearchParams(location.search.slice(1));

    function getJSON(url) {
        return new Promise(function(resolve) {
            var xhr = new XMLHttpRequest();
            xhr.onload = function() {
                let result = {};
                try {
                    const response = xhr.responseText;
                    if(response) {
                        result = JSON.parse(xhr.responseText);
                    }
                } catch(error) {
                    console.warn('Parsing the response from ' + url + ' to JSON failed. Defaulting to an empty object');
                    console.warn(error);
                }
                resolve(result);
            };
            xhr.open('GET', url);
            xhr.setRequestHeader('content-type', 'application/json');
            xhr.send();
        });
    }

    function getRevManifest() {
        return getJSON(manifestURL)
            .catch(() => ({}))
            .then(manifest => new RevManifest(manifest));
    }

    function getScreenConfig(config) {
        const { display, room, site, casinoAssetsURL } = config.screenConfig;
        const { data } = site;

        return {
            env: config.env,
            language: config.language,
            sideGames: config.sideGames,
            launchParams: {
                DTLID: room.localeId,
                BSTYLE: room.skin.toLowerCase(),
                SITEID: site.siteId,
                SITECODE: site.siteCode,
                CALLER: room.caller.toLowerCase(),
                ANNOUNCER: room.announcer.toLowerCase(),
                FREEBUYCONFIRM: display.enableFreeBuyConfirm,
                DEFAULTDISPLAYCURRENCY: display.defaultCurrency,
                ALLOWCURRENCYTOGGLE: display.enableCurrencyToggle,
                TOURNAMENT: false
            },
            siteData: {
                sentryDsn: site.data?.sentry?.dsn || null,
                sentryTrace: site.data?.sentry?.trace === "true" ? site.data?.sentry?.trace :  null,
                autoBuyWagerLimitsDisable: display.enableAutoBuyWagerLimit || null,
                sideGamesAssetsURL: casinoAssetsURL,
                helpURL: site.helpUrl,
                responsibleURL: site.responsibleUrl,
                ecommURL: site.ecomUrl,
                contentfulAccessToken: site.data?.contentful?.accessToken || null,
                contentfulEnvironmentId: site.data?.contentful?.environmentId || null,
                contentfulSpaceId: site.data?.contentful?.spaceId || null
            }
        }
    }

    Promise.all([
        getJSON('/screen-config' + location.search).then(getScreenConfig),
        getRevManifest(),
    ]).then(function(values) {

        const [screenConfig, revManifest] = values;
        const { siteData } = screenConfig;
        const language = params.get('LANGUAGE') || screenConfig.language;

        window.ENV = screenConfig.env;

        document.body.setAttribute('lang', language);

        // --------------------------------------

        let integrations = [];

        if(siteData.sentryDsn && siteData.sentryTrace !== undefined && siteData.sentryTrace !== null) {
            integrations.push(new Sentry.Integrations.BrowserTracing());
        }

        try {
            Sentry.init({
                dsn: siteData.sentryDsn,
                release: VERSION,
                environment: ENV,
                integrations: integrations,
                // We recommend adjusting this value in production, or using tracesSampler for finer control
                tracesSampleRate: siteData.sentryTrace,
            });
        } catch (error) {
            console.error(error);
        }

        let noSleep = new NoSleep();

        function enableNoSleep() {
            // Wake lock seems to be somewhat problematic based on device and browser, so handle and send for analysis
            try {
                noSleep.enable();
            } catch(err) {
                Sentry.captureException(err);
            }        
            document.removeEventListener('touchstart', enableNoSleep, false);
        }

        // Enable wake lock (must be wrapped in a user input event handler e.g. a mouse or touch handler)
        document.addEventListener('touchstart', enableNoSleep, false);

        WebFont.load({
            custom: {
                families: ['Dimbo'],
                urls: [revManifest.getURL('fonts/fonts.css')]
            },
            timeout: 3000
        });

        //var pixelRatio = (window.devicePixelRatio <= 2 ? window.devicePixelRatio : 2);
        let pixelRatio = 2,
            gameWidth = 1024,
            gameHeight = 672,
            isMobile = ParlayBingo.queryString().MOBILE,
            viewType = (isMobile !== undefined && isMobile === 'yes') ? 'compact' : 'default';

        // Fix blur for Moto G 2nd Gen V5
        if (viewType === 'default' && window.innerWidth === 598 && window.innerHeight === 279 &&
            window.screen.width === 640 && window.screen.height === 360) {
            pixelRatio = window.devicePixelRatio;
        }

        if (viewType === 'compact') {
            gameWidth = 320;
            // Forcing height to always be greater than width (aka Portrait mode)
            if (window.innerHeight > window.innerWidth && window.innerHeight < 1024) {
                gameHeight = window.innerHeight * (gameWidth / window.innerWidth);
            } else if (window.innerHeight > window.innerWidth && window.innerHeight >= 1024) { // added iPad device
                gameHeight = 568.8888888888889;
            } else {
                gameHeight = window.innerWidth * (gameWidth / window.innerHeight);
            }
        }

        this.game = new Phaser.Game({
            width: gameWidth * pixelRatio,
            height: gameHeight * pixelRatio,
            renderer: Phaser.CANVAS,
            parent: 'parlay-bingo',
            antialias: true
        });

        this.game.parlay = new ParlayBingo(
            this.game,
            '',
            screenConfig.launchParams,
            language,
            viewType,
            pixelRatio,
            screenConfig.sideGames,
            screenConfig.siteData,
            revManifest
        );

        this.game.state.add('bootstrap', PhaserNS.Bootstrap);
        this.game.state.start('bootstrap', true, false);
    });
}
