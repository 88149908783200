class GameInstance {

    static get PRIZE_FIXED_PARIMUTUEL(){return 0;}
    static get PRIZE_FIXED_GUARANTEED(){return 1;}
    static get PRIZE_FIXED_PARIMUTUEL_WHOLE(){return 2;}
    static get PRIZE_FIXED_GUARANTEED_WHOLE(){return 3;}
    static get PRIZE_FIXED_CONTINUATION() {return 9;}

    constructor(game, gameData, core) {
        this.game = game;
        this.data = gameData;
        this.core = core;

        /**
         * Flag indicates if gameInstance is active for current or next game or disposed
         * @type {boolean}
         */
        this.isActive = true;

        //shortcuts
        this.i18n = core.i18n;
        this.siteData = core.siteData;

        this.tsLoaded = (new Date().getTime());

        this.currencies = {};
        this.uiComponents = {};
        this.partWins = [];
        this.ballCalls = [];
        this.toGoData = {oneTG: 0, twoTG: 0, threeTG: 0, fourTG: 0};
        this.showFreeBuyMessage = false;

        this.ballSfx = null;
        this.timer_gameClock = null;
        this.winnersBox = null;

        this.signal_gameClock = new Phaser.Signal();
        this.signal_ballCall = new Phaser.Signal();
        this.signal_winnerCall = new Phaser.Signal();
        this.signal_joinGameCall = new Phaser.Signal();
        this.signal_jackpotContrib = new Phaser.Signal();

        if (this.data.game_mode === "GAME_MODE") {
            this.currentPart = this.data.current_part;
            this.partWins = this.data.current_part_wins;

            if (this.data.current_calls !== undefined) {
                this.data.current_calls.forEach((c) => {
                    this.ballCalls.push(parseInt(c));
                });
            }
        } else {
            this.currentPart = 0;
        }

        if (this.data.currencies !== undefined) {
            Object.keys(this.data.currencies).forEach(currency => {
                this.currencies[currency] = (new Currency(this, currency, this.data.currencies[currency]));
            });
        }

        // ---- Initialize signal hooks

        this.signalBinding_winnerCall = this.signal_winnerCall.add((wc) => {
            this.updateBalanceInfo();

            this.currentPart++;
            this.partWins.push(wc);

            if (this.currentPart === Object.keys(this.data.game_parts).length) {

                this.core.recentWinners.push({
                    gameName: this.data.game_desc,
                    gameId: this.data.game_sid,
                    partWins: this.partWins,
                    callNum: wc.winners[0].winningCall,
                    callNumValue: this.ballCalls[wc.winners[0].winningCall - 1]
                });

                this.core.winnersBox = new ModalWinners(this, this.partWins, this.data.game_desc, this.data.game_sid, wc.winners[0].winningCall, this.ballCalls[wc.winners[0].winningCall - 1]);

                let sfx = null;
                if (this.ballSfx && this.ballSfx.isPlaying) {
                    this.ballSfx.onMarkerComplete.addOnce(() => {
                        sfx = this.playSound('announcer', 'bingo');
                        sfx.onMarkerComplete.addOnce(() => {
                            this.playSound('bingouisounds', 'winnerbox');
                        });
                    });

                } else {
                    sfx = this.playSound('announcer', 'bingo');
                    sfx.onMarkerComplete.addOnce(() => {
                        this.playSound('bingouisounds', 'winnerbox');
                    });
                }

            } else {
                if (this.ballSfx && this.ballSfx.isPlaying) {
                    this.ballSfx.onMarkerComplete.addOnce(() => {
                        this.playSound('bingouisounds', 'partchange');
                    });
                } else {
                    this.playSound('bingouisounds', 'partchange');
                }

            }
        });

        this.signalBinding_joinGameCall = this.signal_joinGameCall.add((jgc) => {
            this.data.game_players = jgc.numberOfPlayers;
            this.data.game_cards = jgc.numberOfTickets;

            if (this.data.jackpot !== undefined) {
                this.data.jackpot.value = jgc.jackpot.prize;
            }

            _.each(jgc.parts, function (prizeVal, idx) {
                if (prizeVal) {
                    this.data.game_parts[idx].prize = prizeVal;
                }
            }, this);
        });

        this.signalBinding_balanceUpdate = this.core.signal_balanceUpdate.add((balanceInfo) => {

            if (balanceInfo !== undefined) {
                this.data.player_cash = balanceInfo.player_cash;
                this.data.player_bonus = balanceInfo.player_bonus;

                if(balanceInfo.wagered) {
                    this.data.wagered = balanceInfo.wagered;
                }
            }

            if (this.uiComponents.nav !== undefined) {
                this.uiComponents.nav.draw();
            }
        });

        this.signalBinding_jackpotContrib = this.signal_jackpotContrib.add((jpInfo) => {

            console.log('this.data.jackpot', this.data.jackpot);

            if (this.data.jackpot !== undefined && this.data.jackpot.id === jpInfo.id) {
                this.data.jackpot.value = jpInfo.amount;
            }
        });

        if (this.data.game_mode !== "GAME_MODE") {

            let clockListener = null;

            this.timer_gameClock = setInterval(() => {
                let timeRemainOffset = this.calcRemainingTime();
                let humanTimer = ParlayBingo.pad(Math.floor(timeRemainOffset / 60), 2, "0") + ':' + ParlayBingo.pad(Math.floor(timeRemainOffset % 60), 2, "0");

                if (Math.floor(timeRemainOffset) === 30) {
                    this.playSound('announcer', 'lastcall');
                    if (this.core.winnersBox !== null) {
                        this.core.winnersBox.destroy();
                    }

                }

                if (Math.floor(timeRemainOffset) <= 20 &&
                    Math.floor(timeRemainOffset) > 10 &&
                    this.uiComponents.salesClosed === undefined //&&
                    // _.size(this.data.player_cards) < this.data.game_cards_max
                ) {

                    if (this.uiComponents.infobox !== undefined) {
                        this.uiComponents.infobox.closePartDetailsBox();
                    }

                    this.uiComponents.salesClosed = new ModalTicketSalesClosed(this);
                    this.playSound('announcer', 'nosales');

                    this.disableBuyBtn();
                    if (this.uiComponents.purchaseModal !== undefined) {
                        this.uiComponents.purchaseModal.destroy();
                        delete this.uiComponents.purchaseModal;
                    }

                    if (this.uiComponents.modalPickCards !== undefined) {
                        this.uiComponents.modalPickCards.destroy();
                        delete this.uiComponents.modalPickCards;
                    }
                }

                // prep for game mode
                if (Math.floor(timeRemainOffset) <= 10 && this.uiComponents.startClock === undefined) {

                    if (this.uiComponents.infobox !== undefined) {
                        this.uiComponents.infobox.closePartDetailsBox();
                    }

                    if (this.uiComponents.salesClosed !== undefined) {
                        this.uiComponents.salesClosed.destroy();
                        delete this.uiComponents.salesClosed;
                    }

                    this.playSound('announcer', 'starting');

                    if (this.uiComponents.cba !== undefined) {
                        this.uiComponents.cba.draw(CallboardGameStarting);
                    }

                    const td_StartClock = this.core.theme.find("StartClock");
                    if (this.core.isCompactView()) {
                        // slide the start clock up a bit if the chat modal is open on compact view
                        this.uiComponents.startClock = new StartClock(this,
                            td_StartClock.prop('x', 55, true),
                            td_StartClock.prop('y', (this.core.chatModal !== null && this.core.chatModal.isMinimized) ? 225 : 60), true);

                        clockListener = this.core.chat.signal_chatAction.add((action) => {
                            if(action.action === 'minimizeChat') {
                                this.uiComponents.startClock.moveUpDown(225);

                            } else if(action.action === 'maximizeChat') {
                                this.uiComponents.startClock.moveUpDown(60);
                            }
                        });

                    } else {
                        this.uiComponents.startClock = new StartClock(this, td_StartClock.prop('x', 335, true), td_StartClock.prop('y', 210, true));
                    }
                }

                this.signal_gameClock.dispatch(humanTimer, Math.floor(timeRemainOffset));

                if (Math.floor(timeRemainOffset) <= 0) {
                    clearInterval(this.timer_gameClock);

                    if(clockListener) {
                        clockListener.detach();
                    }

                    if (this.uiComponents.startClock !== undefined) {
                        this.uiComponents.startClock.destroy();
                        delete this.uiComponents.startClock;
                    }
                }
            }, 1000);
        }

        let soundLib = null;
        switch (this.data.game_type) {
            case '75BALL':
                soundLib = '75ball';
                break;
            case '80BALL':
                soundLib = '80ball';
                break;
            case '90BALL':
                soundLib = '90ball';
                break;
        }

        if (soundLib) {
            this.signalBinding_ballCall = this.signal_ballCall.add((bc) => {
                this.ballSfx = this.playSound(soundLib, bc.callNumber.toString());
            });
        }

        // this.drawGameState();
    }

    destroy() {
        this.isActive = false;

        this.core.closeMessageModal();
        clearInterval(this.timer_gameClock);

        this.signalBinding_winnerCall.detach();
        this.signalBinding_joinGameCall.detach();
        this.signalBinding_balanceUpdate.detach();
        this.signalBinding_jackpotContrib.detach();
        if(this.signalBinding_ballCall !== undefined) {
            this.signalBinding_ballCall.detach();
        }

        _.each(this.uiComponents, function(obj, key) {
            this.uiComponents[key]?.destroy();
        }, this);
        this.uiComponents = { };
    }

    /**
     * Function also accepts re-draw
     * @param part The part data to use for drawing prize
     * @param txtObj a Phaser text object to manipulate the colors on
     * */
    prizeTxtFormatter(part, txtObj) {
        let prize = '';

        switch(part.fixed) {
            case GameInstance.PRIZE_FIXED_PARIMUTUEL:
            case GameInstance.PRIZE_FIXED_PARIMUTUEL_WHOLE:
                prize = this.currency(part.prize, 'game');
                // let prizeLen = prize.length;
                // prize +=  ' at ' + (part.prizeRate * 100) + "%";
                break;

            case GameInstance.PRIZE_FIXED_GUARANTEED:
            case GameInstance.PRIZE_FIXED_GUARANTEED_WHOLE:
                prize = this.currency(part.prizeString[0], 'game'); // hard coded to 0 since first call only shown on purchase (for now?)
                break;
        }

        txtObj.text = prize;

        // if (part.fixed == GameInstance.PRIZE_FIXED_PARIMUTUEL || part.fixed == GameInstance.PRIZE_FIXED_PARIMUTUEL_WHOLE) {
        //     txtObj.addColor('#3F5BC8', prizeLen);
        // } else {
        //     txtObj.clearColors();
        // }
    }

    /**
     * Should we auto purchase free tickets or make the user get them by pressing the button.  This always purchases MAX cards
     * @param data if undefined will use local data, otherwise can pass data from other gameInstance to calculate output
     * @returns {boolean} If free cards were purchased.
     */
    isFreeBuyConfirm(data = this.data) {
        let rc = false;

        if(!data.game_free_buy_confirm && !this.isMaxCardsBought() &&
            (data.game_ticket_cost === 0 || (data.game_strip_cost === 0 && data.game_strip_only)))
        {
            this.game.parlay.buyTickets(data.game_number, data.game_cards_max, data.game_sid);
            this.showFreeBuyMessage = true;
            rc = true;
        }

        return rc;
    }

    /**
     * Test if AutoBuy is enabled and do the thing if we're supposed to
     @returns {boolean} If auto buy cards were purchased.
     */
    attemptAutoBuy() {
        let rc = false;
        const cost = (this.data.game_strip_only) ? this.data.game_strip_cost : this.data.game_ticket_cost;

        console.log("AUTOBUY attempt - is autobuy enabled?",this.core.autoBuy);
        if(this.core.autoBuy && this.core.autoBuy.isActive) {
            const playerCards = (this.data.player_cards) ? _.size(this.data.player_cards) : 0;
            const maxCardsCanBuy = this.data.game_cards_max - playerCards - this.freeCardsGiven();

            this.core.autoBuy.buyTickets(this.data.game_number, this.data.game_sid, cost, maxCardsCanBuy);
            rc = true;
        }

        return rc;
    }

    /**
     * Calculate if and how many free cards have been given in this game already
     * @param data if undefined will use local data, otherwise can pass data from other gameInstance to calculate output
     */
    freeCardsGiven(data = this.data) {
        return (data.game_free_cards) ? data.game_free_cards : 0;
    }

    /**
     * Calculate if max cards have been purchased for game
     * @param data if undefined will use local data, otherwise can pass data from other gameInstance to calculate output
     */
    isMaxCardsBought(data = this.data) {
        return data.player_cards !== undefined &&
            Object.keys(data.player_cards).length - this.freeCardsGiven(data) === data.game_cards_max;
    }

    updateBalanceInfo() {
        this.core.updateBalanceInfo(this.data.game_number, this.data.game_sid);
    }

    calcRemainingTime() {
        return (this.data.game_mode_time_remaining -  ((new Date().getTime()) - this.tsLoaded)) / 1000;
    }

    playSound(soundLib, sound) {
        return this.core.playSound(soundLib, sound);
    }

    enableBuyBtn() {
        if(this.uiComponents.infobox !== undefined) {
            this.uiComponents.infobox.enableBuyBtn();
        }
    }

    disableBuyBtn() {
        if(this.uiComponents.infobox !== undefined) {
            this.uiComponents.infobox.disableBuyBtn();
        }
    }

    setOption(key, val) {
        this.core.options[key] = val;
    }

    getOption(key) {
        return this.core.options[key];
    }

    getGameGroup() {
        return this.core.getGameGroup();
    }

    getModalGroup() {
        return this.core.getModalGroup();
    }

    toggleSound() {
        return this.core.toggleSound();
    }

    toggleAutoDaub() {
        return this.core.toggleAutoDaub();
    }

    toggleFullScreen() {
        return this.core.toggleFullScreen();
    }

    setNextGameInstance(nextGameInstance) {
        this.uiComponents.infobox.buyData = nextGameInstance.data;
    }

    setToGoData(oneTG, twoTG, threeTG, fourTG) {
        this.toGoData.oneTG   = oneTG;
        this.toGoData.twoTG   = twoTG;
        this.toGoData.threeTG = threeTG;
        this.toGoData.fourTG  = fourTG;
    }

    chatButtonSetFrame(frame) {
        return this.uiComponents.nav.chatButton.setFrames(frame, frame, frame, frame);
    }

    drawGameState(skipComponents = []) {

        // first time draw, hook UI to instance
        if(Object.keys(this.uiComponents).length === 0) {
            if (this.core.isCompactView()) {
                this.uiComponents.nav        = new Nav(this, 0, 0);
                this.uiComponents.infobox    = new InfoBox(this, 0, 60, (this.core.nextGameInstance !== null) ? this.core.nextGameInstance.data : this.data);
                this.uiComponents.cardDeck   = new CardDeck(this, 0, 269);
                this.uiComponents.cba        = new CallboardArea(this, 0, 136);

            } else {
                this.uiComponents.nav        = new Nav(this, 0, 0);
                this.uiComponents.infobox    = new InfoBox(this, 355, 60, (this.core.nextGameInstance !== null) ? this.core.nextGameInstance.data : this.data);
                this.uiComponents.cardDeck   = new CardDeck(this, 355, 305);
                this.uiComponents.cba        = new CallboardArea(this, 355, 180);
            }

            if(this.showFreeBuyMessage) {
                this.core.openMessageModal(this.i18n._t('information.freeBuyConfirm'), true);
            }

            // const td_StartClock = this.core.theme.find("StartClock");
            // this.uiComponents.startClock = new StartClock(this, td_StartClock.prop('x', 335, true), td_StartClock.prop('y', 210, true));
            // this.uiComponents.winnersModal  = new ModalWinners(this,
            //         [{"gameSessionId":9521679,"winners":
            //             [{"userAlias":"dbbpk1","amount":3.55,"winningCall":34,"part":1}], "consolationWinners":[]},
            //             {"gameSessionId":9521679,"winners": [{"userAlias":"dbbpk1","amount":2.44,"winningCall":56,"part":2}], "consolationWinners":[]},
            //             {"gameSessionId":9521679,"winners": [{"userAlias":"dbbpk1","amount":7.5,"winningCall":70,"part":3}], "consolationWinners":[]},
            //             {"gameSessionId":9521679,"winners": [{"userAlias":"dbbpk1","amount":7.5,"winningCall":70,"part":4}], "consolationWinners":[]},
            //             {"gameSessionId":9521679,"winners": [{"userAlias":"dbbpk1","amount":7.5,"winningCall":70,"part":5}], "consolationWinners":[]},
            //             {"gameSessionId":9521679,"winners": [{"userAlias":"dbbpk1","amount":7.5,"winningCall":70,"part":6}], "consolationWinners":[]},
            //             {"gameSessionId":9521679,"winners": [{"userAlias":"dbbpk1","amount":7.5,"winningCall":70,"part":7}], "consolationWinners":[]},
            //             {"gameSessionId":9521679,"winners": [{"userAlias":"dbbpk1","amount":7.5,"winningCall":70,"part":8}],
            //                 "consolationWinners": [
            //                     {"amount": 1, "isBonus": false, "userAlias": "asdfMan"},
            //                     {"amount": 1, "isBonus": false, "userAlias": "asdfMan"},
            //                     {"amount": 1, "isBonus": false, "userAlias": "asdfMan"},
            //                     {"amount": 1, "isBonus": false, "userAlias": "asdfMan"},
            //                     {"amount": 1, "isBonus": false, "userAlias": "asdfMan"},
            //                     {"amount": 1, "isBonus": false, "userAlias": "asdfMan"},
            //                     {"amount": 1, "isBonus": false, "userAlias": "asdfMan"},
            //                 ]
            //             }], "Jackpot HTML5", 9489515, 64, 54, 0);
            //
            // redraw
        } else {
            Object.keys(this.uiComponents).forEach((k) => {
                console.log(k, skipComponents.includes(k), !skipComponents.includes(k));
                console.log({ test3: k });
                if(!skipComponents.includes(k)) {
                    console.log('updating', k);
                    this.uiComponents[k].draw();
                } else {
                    console.log('SKIP', k);
                }
            });
        }
    }

    gameIsRunning() {
        return this.core.gameIsRunning();
    }

    currency(val, valueCurrency) {
        switch(valueCurrency) {
            case 'game':
                valueCurrency = this.data.game_currency;
                break;
            case 'player':
                valueCurrency = this.data.player_currency;
                break;
        }

        let showCurrency = (this.core.options.showCurrency === 'player') ? this.data.player_currency : this.data.game_currency;

        return this.currencies[showCurrency].draw(val, valueCurrency);
    }

    getSiteData(key) {
        if(this.siteData !== null && this.siteData[key] !== undefined) {
            return this.siteData[key];
        } else {
            return null;
        }
    }

}
