class ParlayBingo {
    constructor(phaserGameInstance, assetURL, launchData, locale, viewType, viewDpi, sideGames, siteData, revManifest) {
        const pathNoSlash = (window.location.pathname !== '/') ? window.location.pathname : '';
        const soundMuteOption = window.localStorage.getItem('game.sound.mute');
        const autoDaubOption = window.localStorage.getItem('options.autoDaub');
        this.socket = io.connect({ path: pathNoSlash + '/socket.io' });

        this.game = phaserGameInstance;
        this.assetURL = assetURL;
        this.launchData = launchData;
        this.locale = locale;
        this.viewType = viewType;
        this.viewDpi = viewDpi;
        this.sideGamesData = sideGames;
        this.siteData = siteData;
        this.contentfulCredentials = this.getContentfulCredentials();
        this.revManifest = revManifest;
        this.chatButton = null;
        this.msgModalHandle = null;
        this.chat = null;
        this.chatModal = null;
        this.gameInstance = null;
        this.nextGameInstance = null;
        this.tournament = null;
        this.winnersBox = null; // a handle to the winners box to close it
        this.isTrialAcct = false;
        this.gameNumber = null;

        this.recentWinners = []; // testing data--> = [{"gameName":"BOGO 90 BALL","gameId":9413142,"partWins":[{"gameSessionId":9413142,"winners":[{"userAlias":"dbbpk1","amount":0.6,"winningCall":73,"part":1}],"consolationWinners":[]}],"callNum":73,"callNumValue":60},{"gameName":"FREE 75 BALL","gameId":9413177,"partWins":[{"gameSessionId":9413177,"winners":[{"userAlias":"dbbpk1","amount":0.01,"winningCall":59,"part":1}],"consolationWinners":[]}],"callNum":59,"callNumValue":18}];
        this.i18n = null;
        this.audio = {};
        this.sideGames = null;
        this.autoBuy = null;

        this.options = {
            showCurrency: (this.launchData?.DEFAULTDISPLAYCURRENCY?.toLowerCase() === 'player') ? 'player' : 'game',
            allowCurrencyToggle: this.launchData?.ALLOWCURRENCYTOGGLE,
            soundMute: soundMuteOption ? JSON.parse(soundMuteOption) : true,
            autoDaub: autoDaubOption ? JSON.parse(autoDaubOption) : true,
            fullScreen: false,
        };

        this.signal_balanceUpdate = new Phaser.Signal();
        this.signal_jackpotWin = new Phaser.Signal();
        this.signal_toggleAutoDaub = new Phaser.Signal();
        this.signal_toggleFullScreen = new Phaser.Signal();
        this.signal_autoBuy = new Phaser.Signal();
        this.signal_overlay = new Phaser.Signal();


        this.signal_autoBuy.add((data) => {
            if (data.state == "DISABLED") {
                this.autoBuy = null;
            }
        });

        if (this.launchData.TOURNAMENT) {
            this.tournament = new Tournament(this);
        }

        // socket hooks
        this.socket.on('bingo:loadGameResp', (resp) => {
            // console.log('bingo:loadGameResp', resp);

            if (resp.success) {

                this.gameData = resp.data;

                Sentry.setUser({
                    alias: resp.data.player_alias,
                    game_sid: resp.data.game_sid
                });

                if (resp.data.player_trial) {
                    this.isTrialAcct = true;
                }

                if (this.gameInstance === null || this.gameInstance.data.game_sid !== resp.data.game_sid) {

                    let gi = null;

                    // Next Game == 1
                    if (resp.nextGame) {
                        gi = new GameInstance(this.game, resp.data, this);

                        if (this.gameInstance === null) {
                            this.gameInstance = gi;
                            this.gameInstance.drawGameState();
                            this.gameInstance.setNextGameInstance(this.gameInstance);

                            // Only try to auto buy cards if not free game
                            if (!this.gameInstance.isFreeBuyConfirm()) {
                                this.gameInstance.attemptAutoBuy();
                            }

                        } else {

                            let freeCards = resp.data.game_free_cards !== undefined ? resp.data.game_free_cards : 0;
                            if (_.size(resp.data.player_cards) - freeCards < resp.data.game_cards_max) {
                                this.gameInstance.enableBuyBtn();
                            }

                            if (this.nextGameInstance && this.nextGameInstance !== this.gameInstance) {
                                this.nextGameInstance.destroy();
                            }
                            this.nextGameInstance = gi;
                            this.gameInstance.setNextGameInstance(this.nextGameInstance);

                            // Only try to auto buy cards if not free game
                            if (!this.nextGameInstance.isFreeBuyConfirm()) {
                                this.nextGameInstance.attemptAutoBuy();
                            }

                            console.log('GI ngi', gi);
                        }

                        // Next Game == 0
                    } else {

                        if (resp.data.game_mode === 'GAME_MODE' && _.size(resp.data.player_cards) > 0) {
                            gi = new GameInstance(this.game, resp.data, this);

                            if (this.gameInstance) {
                                this.gameInstance.destroy();
                            }
                            this.gameInstance = gi;
                            console.log('GI gi', gi);

                            this.gameInstance.drawGameState();

                            // Only try to auto buy cards if not free game
                            if (!this.gameInstance.isFreeBuyConfirm()) {
                                this.gameInstance.attemptAutoBuy();
                            }
                        }

                        if (this.nextGameInstance === null) {
                            this.loadGame(true);
                        }
                    }
                }

            } else {
                let msg = (resp.msg !== undefined) ? resp.msg : 'error.general';
                this.openMessageModal(this.i18n._t(msg), false);
            }
        });

        this.socket.on('bingo:getPickedCards', (resp) => {
            const gi = this.gameInstance;

            if (resp.success) {

                const { data } = this.nextGameInstance || this.gameInstance;

                if (resp.data.game_sid === data.game_sid) {

                    const hasNext = resp.data.hasNext;

                    const cardKeys = _.keys(resp.data.cards).sort();

                    if(data.hasNext === undefined) {
                        const hash = data.game_sid;
                        const key = '$$pageSize';
                        const cache = JSON.parse(localStorage.getItem(key) || '{}');
                        const size = cardKeys.length;

                        if(hash in cache) {
                            data.pageSize = cache[hash];
                        } else {
                            cache[hash] = data.pageSize = size;
                            localStorage.setItem('$$pageSize', JSON.stringify({ [hash]: size }))
                        }
                    }

                    const pageSize = data.pageSize;
                    const chunked = _.chunk(cardKeys, pageSize);

                    const selected = data.selected || {};

                    const isStripActive = data.game_strip_active;
                    const isStripsOnly = data.game_strip_only;

                    for(const id of resp.data.bought) {
                        if(isStripsOnly) {
                            const [ stripId ] = id.split('-');
                            delete selected[stripId];
                        } else {
                            delete selected[id];
                        }
                    }

                    const selectedKeys = _.keys(selected);

                    const meta = JSON.parse(localStorage.getItem('$$page'));
                    const page = meta[data.game_sid] || 0;

                    let cards = _.flatten(chunked.slice(page));
                    let activatedKeys = selectedKeys.concat(resp.data.bought);

                    if(isStripActive) {
                        activatedKeys = [...new Set(activatedKeys.map(key => key.split('-').shift()))];
                    }

                    for(const cardId in resp.data.cards) {

                        if(isStripActive) {
                            const [ stripId ] = cardId.split('-');
                            if(activatedKeys.includes(stripId)) {
                                cards.push(cardId);
                            }
                        } else if(activatedKeys.includes(cardId)) {
                            cards.push(cardId);
                        }

                    }

                    cards = [...new Set(cards)].sort().reduce((result, key) => {
                        result[key] = resp.data.cards[key];
                        return result;
                    }, {});

                    data.new_cards = cards;
                    data.bought = resp.data.bought;
                    data.selected = selected;
                    data.hasNext = hasNext;

                    gi.uiComponents.pickCardDeck.buildCards();
                    gi.uiComponents.modalPickCards.updatePickedCardsCost();

                    this.updateBalanceInfo(data.game_number, data.game_sid);

                }
            } else {
                this.openMessageModal(this.i18n._t(resp.msg));
            }

            gi?.uiComponents?.modalPickCards?.toggleButtons(gi?.data?.hasNext !== false);

        });

        this.socket.on('bingo:buyCards', (resp) => {
            this.purchaseCardsModal.destroy();
            if (resp.success) {

                let gi = null;

                if (resp.data.game_sid === this.gameInstance.data.game_sid) {
                    gi = this.gameInstance;
                    gi.data.game_free_cards = parseInt(resp.data.totalFreeCards);
                    gi.data.player_cards = resp.data.cards;

                    // rebuild the card deck if new cards purchased
                    if (gi.uiComponents.cardDeck !== undefined) {
                        gi.uiComponents.cardDeck.buildCards();
                    }

                    gi.drawGameState();
                    gi.enableBuyBtn();

                    // let freeCards = gi.data.game_free_cards !== undefined ? gi.data.game_free_cards : 0;
                    // if (_.size(gi.data.player_cards) - freeCards < gi.data.game_cards_max) {
                    //     gi.enableBuyBtn();

                    // } else {
                    //     gi.disableBuyBtn();
                    // }

                } else {
                    gi = this.nextGameInstance;
                    gi.data.game_free_cards = parseInt(resp.data.totalFreeCards);
                    gi.data.player_cards = resp.data.cards;


                    this.gameInstance.enableBuyBtn();

                    // let freeCards = gi.data.game_free_cards !== undefined ? gi.data.game_free_cards : 0;
                    // if (_.size(gi.data.player_cards) - freeCards < gi.data.game_cards_max) {
                    //     this.gameInstance.enableBuyBtn();
                    // } else {
                    //     this.gameInstance.disableBuyBtn();
                    // }
                }

                this.signal_balanceUpdate.dispatch({
                    player_cash: resp.data.player_cash,
                    player_bonus: resp.data.player_bonus,
                    wagered: resp.data.wagered
                });

                this.gameInstance.playSound('bingouisounds', 'buy');

                this.gameInstance.uiComponents?.purchaseModal?.updateBoughtCardDisplay();

            } else {
                this.openMessageModal(this.i18n._t(resp.msg));
            }
        });

        this.socket.on('bingo:joinGame', (resp) => {
            let gi = null;
            if (this.gameInstance && this.gameInstance.data.game_sid === resp.gameSessionId) {
                gi = this.gameInstance;

            } else if (this.nextGameInstance && this.nextGameInstance.data.game_sid === resp.gameSessionId) {
                gi = this.nextGameInstance;
            }

            if (gi) {
                gi.signal_joinGameCall.dispatch(resp);
            }
        });

        this.socket.on('bingo:accountStatus', (resp) => {
            if (resp.success) {
                this.signal_balanceUpdate.dispatch({
                    player_cash: resp.data.player_cash,
                    player_bonus: resp.data.player_bonus,
                    wagered: resp.data.wagered
                });
            } else {
                this.openMessageModal(this.i18n._t(resp.msg));
            }
        });

        this.socket.on('bingo:gameModeChange', (resp) => {
            console.log('bingo:gameModeChange', resp);

            if (resp.data.status === "GAME_OVER") {
                this.gameInstance.destroy();

                // This switches the next game instance into the current game instance
                this.gameInstance = this.nextGameInstance;
                console.log('GI ngi --> gi', this.gameInstance);
                // this.loadGame(true);

            } else if (resp.data.status === "GAME_MODE") {
                this.loadGame(true);
                this.gameInstance.data.game_mode = resp.data.status;

            } else if (resp.data.status === "GAME_CANCELLED") {
                this.openMessageModal(this.i18n._t('information.gameCancelled'), true);
            }

            if (this.gameInstance) {
                this.gameInstance.drawGameState();
            }
        });

        this.socket.on('bingo:callMessage', (resp) => {
            // console.log('bingo:callMessage', resp);

            if (this.gameInstance) {
                this.gameInstance.ballCalls.push(resp.callNumber);
                this.gameInstance.signal_ballCall.dispatch(resp);
            }
        });

        // var numbers = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,
        //     37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,
        //     74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90];
        //
        // setInterval(function() {
        //     this.gameInstance.ballCalls.push(numbers[0]);
        //     this.gameInstance.signal_ballCall.dispatch({
        //         callNumber: numbers[0]
        //     });
        //     numbers.shift();
        // }, 800);

        this.socket.on('bingo:winnerMessage', (resp) => {
            console.log('bingo:winnerMessage', resp);
            this.gameInstance.signal_winnerCall.dispatch(resp);
        });

        this.socket.on('bingo:JackpotWinnerMessage', (resp) => {
            console.log('bingo:JackpotWinnerMessage', resp);
            this.signal_jackpotWin.dispatch(resp);

            new JackpotModal(this.gameInstance, resp);
        });

        this.socket.on('bingo:JackpotContributionMessage', (resp) => {
            console.log('bingo:JackpotContributionMessage', resp);
            this.gameInstance.signal_jackpotContrib.dispatch(resp);
        });

        this.socket.on('disconnect', () => {
            this.openMessageModal(this.i18n._t('error.socketConnectionInterrupted'), false);
        });

        this.socket.io.on('reconnect', () => {
            // if (this.gameInstance) {
            //     this.socket.emit('rejoin', this.gameInstance.data.game_sid);
            // }
            // if (this.nextGameInstance) {
            //     this.socket.emit('rejoin', this.nextGameInstance.data.game_sid);
            // }

            // check if MessageModal is open and there is a game instance
            if (this.msgModalHandle && this.gameInstance) {
                this.msgModalHandle.destroy();
                this.msgModalHandle = null;
            }

            this.gameInstance.destroy();
            this.gameInstance = null;
            this.loadGame();

        });

        ["", "webkit", "moz", "ms"].forEach(
            prefix => document.addEventListener(prefix + "fullscreenchange", (event) => {
                this.options.fullScreen = ParlayBingo.isFullScreen();
                this.signal_toggleFullScreen.dispatch(this.options.fullScreen);
            }, false)
        );

        MarketingModal.initialize(this);

    }

    isCompactView() {
        return this.viewType === 'compact';
    }

    getGameGroup() {
        return this.groups.game;
    }

    getModalGroup() {
        return this.groups.modal;
    }


    postBootstrap() {
        this.theme = new Theme(this);
    }


    postPreloader() {
        this.addSoundLibrary('bingouisounds');
        this.addSoundLibrary('announcer');
        this.addSoundLibrary('75ball');
        this.addSoundLibrary('80ball');
        this.addSoundLibrary('90ball');

        const base = this.game.cache.getJSON('locale_base') || {}
        const main = this.game.cache.getJSON('locale');

        this.i18n = new i18n(this.locale, { ...base, ...main });
    }

    postInit() {

        // z-index for game, modal always on top
        this.groups = {
            game: this.game.add.group(),
            modal: this.game.add.group()
        };

        this.loadGame();

        // Set sound option
        this.game.sound.mute = this.options.soundMute;

        let queryString = ParlayBingo.queryString();
        this.chat = new Chat(this, {
            configID: parseInt(queryString.GAMEID.match(/\d+/g)[0]),
            siteID: parseInt(this.launchData.SITEID),
            dtlID: parseInt(this.launchData.DTLID),
            userId: queryString.USERID,
            sessionId: queryString.SESSIONID
        });

        if (!this.isCompactView()) {

            if (this.sideGamesData.length > 0) {
                this.sideGames = new SideGames(this, 40, 140);
            } else {
                this.getGameGroup().add(
                    this.game.add.sprite(180, 130, 'brand', 'BingoBrand.png')
                );
            }

            this.chat.signal_chatAction.add((action) => {
                if (action.action === "disconnect" && action.isEjected) {
                    this.chatButton.visible = false;
                }
            });

            // recieve the 'initialized' action from chat (means we connected)
            this.chat.signal_chatAction.addOnce((action) => {

                if (_.size(this.chat.rooms) > 0) {
                    this.createChatToggleButton();

                    if (this.chat.autoOpen && !this.chatModal) {
                        let yStart = 360;
                        let height = 5;

                        this._openChatModal(yStart, height);

                        this.signalBinding_chatAction = this.chat.signal_chatAction.add((params) => {
                            if (params.action === 'disconnect' && this.chatModal) {
                                this.chatModal.destroy();
                            }
                        });
                    }
                }
            });
        }
    }

    createChatToggleButton() {
        // code below is a snip from BaseComponent.js createButton, just there's nothing here extending that class so copy/paste
        let btn = this.game.add.button(15 * BaseComponent.getScaleForDPI(), 605 * BaseComponent.getScaleForDPI(), 'bingo', () => {
            let yStart = 360;
            let height = 5;

            this.playSound('bingouisounds', 'click');
            if (this.gameInstance.core.chat.initialized && !this.gameInstance.core.chatModal) {
                this.chat.changeRoom();
                this._openChatModal(yStart, height);
            }
        },
            this, 'Button_Chat.png', 'Button_Chat.png', 'Button_Chat.png');
        btn.input.pixelPerfectOver = true;
        btn.input.pixelPerfectClick = true;
        btn.input.useHandCursor = true;
        this.chatButton = btn;
        this.getGameGroup().add(btn);
    }

    _openChatModal(yStart, height) {
        if (this.sideGames && this.sideGames.isGameOpen) {
            yStart = 360;
            height = 5;
        }

        this.chatModal = new ChatModal(this, this.chat, 20, yStart, height);
    }

    closeChat(leaveRoom) {
        if (this.chatModal !== null) {
            this.chatModal.destroy(leaveRoom);
            this.chatModal = null;
        }
    }

    gameIsRunning() {
        return this.gameInstance.data.game_mode === "GAME_MODE";
    }

    toggleFullScreen() {
        if (ParlayBingo.isFullScreen()) {
            ParlayBingo.exitFullScreen();
            this.options.fullScreen = false;
        } else {
            ParlayBingo.requestFullScreen(document.documentElement);
            this.options.fullScreen = true;
        }
    }

    toggleSound() {
        this.game.sound.mute = this.options.soundMute = !this.options.soundMute;

        // save sound option to local storage
        window.localStorage.setItem('game.sound.mute', this.game.sound.mute)
    }

    toggleAutoDaub() {
        this.options.autoDaub = !this.options.autoDaub;
        this.signal_toggleAutoDaub.dispatch(this.options.autoDaub);

        // save auto daub option to local storage
        window.localStorage.setItem('options.autoDaub', this.options.autoDaub);
    }

    toggleCurrency() {
        this.options.showCurrency = (this.options.showCurrency === 'player') ? 'game' : 'player';
        this.signal_balanceUpdate.dispatch();
    }

    addSoundLibrary(libName) {

        let sfxMarkers = this.game.cache.getJSON(libName);
        let sfx = this.game.add.audio(libName);
        //sfx.allowMultiple = true;

        _.each(sfxMarkers, function (mkr, name) {
            sfx.addMarker(name, mkr[0] / 1000, mkr[1] / 1000);
        });

        this.audio[libName] = sfx;
    }

    playSound(soundLib, sound) {
        return this.audio[soundLib].play(sound);
    }


    loadGame(nextGame) {
        let data = { game_id: ParlayBingo.queryString("GAMEID") };

        if (this.gameNumber !== null) {
            data.game_number = this.gameNumber;

        } else {
            data.next_game = (nextGame !== undefined ? '1' : '0');
        }

        this.socket.emit('bingo:loadGame', ParlayBingo.injectStateInfo(data));
    }

    updateBalanceInfo(gameNum, gameSID) {
        this.socket.emit('bingo:getAccountStatus', ParlayBingo.injectStateInfo({
            game_id: ParlayBingo.queryString("GAMEID"),
            game_number: gameNum,
            sid: gameSID
        }));
    }

    buyTickets(gameNum, numCards, gameSID) {
        console.log({ buyTickets: {gameNum, numCards, gameSID} })

        this.gameInstance.disableBuyBtn();

        if (this.gameInstance !== null) {
            this.purchaseCardsModal = new ModalPurchasePleaseWait(this.gameInstance);
        }


        this.socket.emit('bingo:buyCards', ParlayBingo.injectStateInfo({
            game_id: ParlayBingo.queryString("GAMEID"),
            game_number: gameNum,
            num_cards: numCards,
            sid: gameSID
        }));
    }

    // pick cards
    getPickedCards(data, next = false) {
        const gi = this.gameInstance;
        gi.uiComponents?.modalPickCards?.toggleButtons(false);

        const { hasNext } = (this.nextGameInstance || this.gameInstance).data;

        const meta = JSON.parse(localStorage.getItem('$$page') || '{}');
        let page = meta[data.game_sid] || 0;

        if(next) {
            page += 1;
        }

        localStorage.setItem('$$page', JSON.stringify({ [data.game_sid]: page }))

        this.socket.emit('bingo:getPickedCards', ParlayBingo.injectStateInfo({
            game_id: ParlayBingo.queryString("GAMEID"),
            game_number: data.game_number,
            isNext: hasNext && next,
            sid: data.game_sid,
            pageSize: this.getPickedCardsPageSize(data)
        }));
    }

    getPickedCardsPageSize(data) {

        const meta = ParlayBingo.PICKED_CARDS[data.game_type];

        if(data.fixed_game) {
            let pageSize = Math.max(
                meta.min,
                data.fixed_game * 2
            );

            const remainder = pageSize % meta.cols;

            if(remainder) {
                pageSize += meta.cols - remainder;
            }

            return pageSize;
        }

        return meta.min;
    }

    buyPickedCards(gameNum, cardIds, gameSID) {
        console.log({ buyCards: cardIds })
        this.gameInstance.disableBuyBtn();

        if (this.gameInstance !== null) {
            this.purchaseCardsModal = new ModalPurchasePleaseWait(this.gameInstance);
        }

        this.socket.emit('bingo:buyPickedCards', ParlayBingo.injectStateInfo({
            game_id: ParlayBingo.queryString("GAMEID"),
            game_number: gameNum,
            ids: cardIds,
            sid: gameSID
        }));
    }

    openMessageModal(msg, isClosable = true) {
        let newHandle = null;
        let gameHandle = (!this.gameInstance) ? this.game : this.gameInstance;

        // check if another closable MessageModal is open. If true, close it and replace
        if (!this.msgModalHandle) {
            this.msgModalHandle = new MessageModal(gameHandle, msg, isClosable);
            newHandle = this.msgModalHandle;
        }
        else if (this.msgModalHandle && this.msgModalHandle.isClosable) {
            this.msgModalHandle.destroy();
            this.msgModalHandle = null;
            this.msgModalHandle = new MessageModal(gameHandle, msg, isClosable);
            newHandle = this.msgModalHandle;
        }

        return newHandle;
    }

    closeMessageModal() {
        if (this.msgModalHandle && this.msgModalHandle.isClosable) {
            this.msgModalHandle.destroy();
            this.msgModalHandle = null;
        }
    }

    autobuy_enable(games, tickets, isAnyPrice, maxTicketPrice, maxLoss) {
        this.autoBuy = new AutoBuy(this, games, tickets, isAnyPrice, maxTicketPrice, maxLoss);
        this.autoBuy.start(this.gameInstance.data.player_cash + this.gameInstance.data.player_bonus);
    }

    autobuy_disable() {
        this.autoBuy.stop();
        this.autoBuy = null;
    }

    hasValidContentfulCredentials() {
        return (this.siteData.contentfulSpaceId &&
            this.siteData.contentfulEnvironmentId &&
            this.siteData.contentfulAccessToken);
    }

    getContentfulCredentials() {

        const {
            contentfulSpaceId: contentfulSpace = null,
            contentfulEnvironmentId: contentfulEnvironment = null,
            contentfulAccessToken = null,
        } = this.siteData || {};

        return {
            contentfulSpace,
            contentfulEnvironment,
            contentfulAccessToken,
        };

    }

    // Static helper functions
    // -------------------------------

    // number padding
    static pad(n, width, z) {
        z = z || '0';
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }

    // extract data from query string
    static queryString(val) {
        let result = {}, queryString = location.search.slice(1),
            re = /([^&=]+)=([^&]*)/g, m;

        while ((m = re.exec(queryString))) {
            result[decodeURIComponent(m[1])] = decodeURIComponent(escape(m[2]));
        }

        if (val !== undefined) {
            return result[val];
        } else {
            return result;
        }
    }

    // extract hostname via JS voodoo
    static getHostnameFromURL(URL) {
        let a = document.createElement('a');
        a.href = URL;
        return a.hostname;
    }

    static isFullScreen() {
        return (document.fullScreenElement && document.fullScreenElement !== null) || document.mozFullScreen || document.webkitIsFullScreen;
    }

    static requestFullScreen(element) {
        if (element.requestFullscreen)
            element.requestFullscreen();
        else if (element.msRequestFullscreen)
            element.msRequestFullscreen();
        else if (element.mozRequestFullScreen)
            element.mozRequestFullScreen();
        else if (element.webkitRequestFullscreen)
            element.webkitRequestFullscreen();
    }

    static exitFullScreen() {
        if (document.exitFullscreen)
            document.exitFullscreen();
        else if (document.msExitFullscreen)
            document.msExitFullscreen();
        else if (document.mozCancelFullScreen)
            document.mozCancelFullScreen();
        else if (document.webkitExitFullscreen)
            document.webkitExitFullscreen();
    }

    static injectStateInfo(data) {
        let queryString = ParlayBingo.queryString();
        data._stateInfo = {
            userId: queryString.USERID,
            sessionId: queryString.SESSIONID,
            gameId: queryString.GAMEID
        };
        return data;
    }

    static isInIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    }

    static get PICKED_CARDS() {
        return {
            '75BALL': {
                min: 15,
                cols: 5,
            },
            '80BALL': {
                min: 15,
                cols: 5,
            },
            '90BALL': {
                min: 24,
                cols: 4,
            },
        };
    };

}
